.fixed-height {
  height: 224px;
  overflow: auto;
  display: flex;
  overflow-x: hidden;
}
.band-primary {
  background-color: var(--lf-dark-blue);
  *, h3 {
    color: var(--lf-white);
  }
}
.band-off {
  background-color: var(--lf-light-gray);
  * {
    color: var(--lf-dark-blue);
  }
}

.band-light {
  background-color: var(--lf-white);
  * {
    color: var(--lf-mid-gray);
  }
  h3 {
    color: var(--lf-dark-blue);
  }
  img {
    height: 100%;
    object-fit: cover;
  }
  .brand {
    color: var(--lf-dark-blue);
  }
  .company {
    color: var(--lf-light-blue);
  }
}
.flex-20 {
  flex-basis: 20%;
}
[class*="band-"] {
  flex-grow: 1;
  padding: 14px;
  * {
    &:not(h3) {
      font-size: 12px;
    }
    margin: 0;
  }
}
.lf-card-header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--lf-dark-blue);
  height: 40px;
  flex-grow: 0;
  align-items: end;
}
