
/** Variabili Custom **/
$icon-custom-path: "./assets/themes/icons";

%icon {
  content: "";
    -webkit-mask-size: contain;
    mask-size: contain;

    -webkit-mask-position: 50% 50%;
    mask-position: 50% 50%;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: var(--lf-white);
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: bottom;
}
[class^="icon-lf-"], [class*="icon-lf-"] {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  // display: contents;

    &::before {
      @extend %icon;
    }
    &.icon-disabled::before {
      background-color: var(--lf-gray);
    }




  &.icon-mini {
    &::before {
      width: 9px;
      height: 9px;
    }
  }
  &.icon-small {
    &::before {
      width: 16px;
      height: 16px;
    }
  }
  &.active {
    &::before {
      background-color: var(--lf-dark-blue);
    }
  }

  &.icon-big {
    &::before {
      width: 32px;
      height:32px;
    }
  }
  &.icon-lg {
    &::before {
      font-size: 25px;
    }
  }

  &.icon-bigger {
    &::before {
      width: 36px;
      height: 36px;
    }
  }

  &.icon-xxl {
    &::before {
      width: 42px;
      height: 42px;
    }
  }
}


@mixin makeIcon($name, $svg) {
  [class*="icon-lf-#{$name}"]::before {
    -webkit-mask-image: url(#{$icon-custom-path}/#{$svg});
    mask-image: url(#{$icon-custom-path}/#{$svg});
  }
}

@include makeIcon(alert , 'alert.svg');
@include makeIcon(calendario , 'calendario.svg');
@include makeIcon(campagne , 'campagne.svg');
@include makeIcon(cart , 'cart.svg');
@include makeIcon(chevron-left , 'chevron-left.svg');
@include makeIcon(chevron-right , 'chevron-right.svg');
@include makeIcon(home , 'home.svg');
@include makeIcon(logout , 'logout.svg');
@include makeIcon(options , 'options.svg');
@include makeIcon(pv , 'pv.svg');
@include makeIcon(search , 'search.svg');
@include makeIcon(login-logo , 'login-logo.svg');
@include makeIcon(pdf , 'pdf.svg');
@include makeIcon(delete , 'delete.svg');
@include makeIcon(edit , 'edit.svg');
@include makeIcon(image , 'image.svg');
@include makeIcon(excel , 'excel.svg');
@include makeIcon(add , 'add.svg');
@include makeIcon(check , 'check.svg');
@include makeIcon(uploadxls , 'uploadxls.svg');
