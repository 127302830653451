
.chart-legend-wrap {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  gap: 6px;
  min-height: 40px;

}
.chart-legend-list {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.chart-legend-bullet {
  border-radius: 100px;
  display: inline-block;
  flex-shrink: 0;
  height: 10px;
  margin-right: 10px;
  margin-top: 3px;
  width: 10px;
}
.label-legend {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
