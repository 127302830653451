:root {
  --lf-font: 'Inter' , sans-serif;
  --lf-light-gray: #f8f8f8;
  --lf-gray: #D9D9D9;
  --lf-mid-gray: #828282;
  --lf-hard-gray: #333333;
  --lf-black: #000000;
  --lf-light-blue: #85B7D9;
  --lf-flash-blue: #70BBEE;
  --lf-blue: #E8F1F8;
  --lf-dark-blue: #093F56;
  --lf-red: #FF7978;
  --lf-green: #37BB09;
  --lf-warn: #FFD25F;
  --lf-white: #FFFFFF;
}
