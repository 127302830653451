.dialog-header {
  padding: 27px 20px;
  background-color: var(--lf-dark-blue);
  color: var(--lf-white);
  font-size: 16px;
  font-weight: 800;
  align-items: center;
}

#cart-dialog {
  left: -91px !important;
  top: 93px;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    right: 43px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 23px solid var(--lf-dark-blue);
  }

}
