
  //.dashboard {
    .border {
      table.mat-mdc-table {
        .mat-mdc-header-row th {
          &:first-of-type {
            border-top-left-radius: 10px;
          }
          &:last-of-type {
            border-top-right-radius: 10px;
          }
        }
      }
    }
    table.mat-mdc-table {
      border-radius: 10px;
      &.mat-elevation-z8, &.mat-mdc-elevation-specific.mat-elevation-z8 {
        box-shadow: 0px 2.97px 2.97px 0px #0000001A;
      }
      .mat-mdc-header-row {
        background-color: var(--lf-light-gray);
        height: 35px;
        th {
          // &:first-of-type {
          //   border-top-left-radius: 10px;
          // }
          // &:last-of-type {
          //   border-top-right-radius: 10px;
          // }
          font-weight: 400;
          font-size: 14px;
          padding-inline: 6px;
          text-align: center;

          color: var(--lf-dark-blue)!important;
          > div {
            justify-content: center;
          }
        }
      }
      tbody {
        tr:last-of-type {
          td {
            &:first-of-type {
              border-bottom-left-radius: 10px;
            }
            &:last-of-type {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
      td {
        text-align: center;
        font-size: 14px;
        &.cdk-column-inizio, &.cdk-column-mese {
          color: var(--lf-mid-gray);
          font-weight: 500;
        }
        color: var(--lf-dark-blue);
        font-weight: 400;
        &:not(:last-of-type) {
          padding-inline: 4px;
          border-right: 1px solid var(--lf-gray);

        }
        button[class*="icon-"] {
          padding-inline: 8px;
        }
      }
      a.detail {
        text-decoration: underline;
        color: var(--lf-dark-blue);
        text-underline-offset: 3px;
      }
    }
  //}


  .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text {
      opacity: 1;
    }

  }

  ::ng-deep {
    .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {

      background-color: var(--lf-light-blue)!important;
      color: var(--lf-light-blue)!important;
    }
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {

    background-color: var(--lf-light-blue)!important;
    color: var(--lf-light-blue)!important;
  }
table td.blink {
    background-color: #fff7b53b!important;
  }
