.btn-lf {
  min-width: 55px;
  font-weight: 500;
    border-radius: 23px;
    padding: 2px 6px;
    font-size: 12px;
    margin: 0;
  &.chiusa , &.ko, &.high, &.ok {
    background-color: var(--lf-red);
    color: var(--lf-white);
  }
  &.pending , &.idle {
    border: 1px solid var(--lf-dark-blue);
  }
  &.attiva, &.in-lavorazione  {
    background-color: var(--lf-green);
    color: var(--lf-white);
  }
   &.low {
    background-color: var(--lf-blue);
    color: var(--lf-mid-gray);
  }
  &.senza-conferma, &.inattiva, &.standard, &.trattativa-in-corso {
    background-color: var(--lf-warn);
    color: var(--lf-white);
  }
}

.btn-new {
  padding: 9px 21px;
  border-radius: 10px;
  color: var(--lf-dark-blue);;
  background-color: var(--lf-light-blue);
  border: none;
  margin-block: 16px;
  margin-left: auto;
}

button.link  , a.link{
  color: var(--lf-light-blue)!important;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}
.btn-group {
  border-radius: 10px;
  border: 1px solid var(--lf-mid-gray);
  button:not(:first-of-type), button:not(:last-of-type) {
    border-inline: 1px solid var(--lf-mid-gray);
  }
}
.btn-switch {
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 400;
  &.active {
    font-weight: 500;
    color: var(--lf-light-blue);
  }
}

.btn-circle {
  margin-inline-start: 12px;
  padding: 0;
  line-height: 0;
  border-radius: 50%;
  &:hover {
    mat-icon {
      border-radius: 100%;
      color: var(--lf-black);
      background-color: var(--lf-white);

    }
  }
  mat-icon {
    border-radius: 100%;
    color: var(--lf-white);
    background-color: inherit;

  }
}
.zoom-in {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: var(--lf-white);
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn-ol {
  border: 1px solid var(--lf-dark-blue);
  color: var(--lf-dark-blue);
  padding: 9px 21px;
  border-radius: 10px;
  background-color: var(--lf-white);
}
