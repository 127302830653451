html {
  overflow: hidden;
}

body {
  font-family: var(--lf-font);
  margin: 0;
}

a {
  cursor: pointer;
}

.lf-card {
  background-color: var(--lf-white);
  position: relative;
  padding: 12px;
  box-shadow: 0px 2.97px 2.97px 0px #0000001a;
  border-radius: 10px;
  h3 {
    color: var(--lf-dark-blue);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: var(--lf-mid-gray);

  }
}

.content {
  overflow-y: hidden;
  padding-inline: 36px;
  // max-height: calc(100vh - 160px);
  // height: 100%;
  overflow-y: auto;

}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--lf-dark-blue);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: var(--lf-gray);
  border-radius: 4px;
}

.bg-confirm {
  background-color: var(--lf-green);
}
.bg-discard {
  background-color: var(--lf-red);
}
input::placeholder {
  color: var(--lf-warn);
}
